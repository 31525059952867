import Files from '@/modules/ProjectPreview-Files/tabs/FilesV2/FilesTab.vue'
import GoogleDriveTab from '@/modules/ProjectPreview-Files/tabs/GoogleDrive/GoogleDriveTab.vue'
import OffersTab from '@/modules/ProjectPreview-Files/tabs/Offers/OffersTab.vue'
import CanvaTab from '@/modules/ProjectPreview-Files/tabs/Canva/CanvaTab.vue'

export default {
    name: 'FilesTab',
    components: {
        Files,
        GoogleDriveTab,
        OffersTab,
        CanvaTab
    },
    props: {
        id: [Number, String] //from route prop
    },

    data() {
        return {
            active_tab: 'files',
            view: 'files',
            tabs: [{
                    name: 'Uploaded',
                    view: `files`,
                    icon: 'mdi-file'
                },
                {
                    name: 'Google Drive',
                    view: `google-drive`,
                    icon: 'mdi-google-drive'
                },
                {
                    name: 'From Offers',
                    view: `offers`,
                    icon: 'mdi-cart-outline'
                },
                {
                    name: 'From Canva',
                    view: `canva`,
                    icon: 'mdi-alpha-c-circle'
                }
            ],
            others: []
        }
    },
    created() {
        if (this.$route.params.view) {
            this.view = this.$route.params.view
            let index = this.tabs.findIndex((i) => i.view === this.view)
            this.active_tab = ~index ? index : 0
        }
    },

    methods: {
        changeTab(tab) {
            this.view = tab
            this.$router
                .push({
                    name: 'project-preview-files',
                    params: {
                        id: this.id,
                        view: this.view
                    }
                })
                .catch(() => {})
        }
    }
}