<template>
  <v-card flat class="ma-1" v-bind="$attrs">
    <v-card-text style="overflow: hidden">
      <vue-dropzone
        id="dropzone"
        ref="mydropzone"
        duplicate-check
        use-custom-slot
        :options="dropzoneOptions"
        v-bind="$attrs"
        @vdropzone-success="fileAdded"
        @vdropzone-error="uploadError"
        @vdropzone-removed-file="fileRemoved"
        @vdropzone-max-files-exceeded="maxFileExceed"
        @vdropzone-duplicate-file="duplicateFile"
      >
        <!--   
        @vdropzone-canceled=""
        @vdropzone-sending=""
        -->
        <v-sheet class="align-self-center py-2" color="transparent">
          <div class="file__icon">
            <v-icon size="80" color="primary">mdi-file-plus</v-icon>
          </div>
          <div class="drop__title primary--text subtitle-2 mb-1">
            Drag & Drop Files Here
          </div>
          <div class="drop__text primary--text subtitle-2 mb-1">or</div>
          <div class="drop__btn">
            <v-btn depressed class="text-none px-5 subtitle-2" color="primary">
              Choose your files
            </v-btn>
          </div>
        </v-sheet>
      </vue-dropzone>
    </v-card-text>
    <v-card-actions class="pb-2 pt-0">
      <v-spacer v-if="mdAndUp" />
      <v-btn
        class="mx-1 text-none"
        color="primary"
        @click.stop="clearAll"
        :disabled="!files.length || sending"
      >
        <v-icon color="white" left> mdi-delete </v-icon> Clear All
      </v-btn>
      <v-btn
        color="primary"
        class="mx-1 text-none"
        @click="save"
        :loading="sending"
        :disabled="!files.length || sending"
      >
        <v-icon left>mdi-cloud-upload-outline</v-icon>
        Upload Selected Files
      </v-btn>
      <v-spacer v-if="mdAndUp" />
      <strong
        :class="{ 'd-inline-block': !mdAndUp }"
        class="primary--text subtitle-2 mr-2"
      >
        {{ file_count_status }}
      </strong>
    </v-card-actions>
  </v-card>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'AttachmentDropzone',
  inheritAttrs: false,
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    needConfirmation: { type: Boolean, default: true },
    hasAddLink: { type: Boolean, default: true }
  },
  created() {
    this.$event.$on('dropzone-reset', () => {
      this.sending = false
      this.disabled = false
      this.clearAll()
    })
  },
  data: () => ({
    files: [],
    maxFiles: 10,
    sending: false,
    disabled: false
  }),
  computed: {
    dropzoneOptions() {
      return {
        thumbnailWidth: 180,
        thumbnailHeight: 180,
        method: 'post',
        duplicateCheck: true,
        url: this.settings.apiHostBaseURL + `/api2/attachments/dropzone`,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        maxFiles: this.maxFiles,
        maxFilesize: 8, //mb
        addRemoveLinks: true,
        clickable: !this.disabled
      }
    },
    file_count_status() {
      return `${this.files.length}/${this.maxFiles}`
    }
  },
  methods: {
    fileAdded(file, response) {
      this.files.push(response)
    },
    fileRemoved(file) {
      let attachment =
        file.xhr.hasOwnProperty('response') &&
        typeof file.xhr.response === 'string'
          ? JSON.parse(file.xhr.response)
          : false
      if (attachment) {
        let index = this.files.findIndex((i) => i.uuid === attachment.uuid)
        if (~index) {
          this.files.splice(index, 1)
        }
      }
    },
    uploadError(file, message, xhr) {
      this.appSnackbar(message, 'error')
      this.$refs.mydropzone.removeFile(file)
    },
    maxFileExceed(file) {
      this.$refs.mydropzone.removeFile(file)
    },
    duplicateFile(file) {
      this.appSnackbar('File is a duplicate, file removed', 'error')
      this.$refs.mydropzone.removeFile(file)
    },
    clearAll() {
      if (this.$refs.hasOwnProperty('mydropzone') && this.$refs.mydropzone) {
        this.$refs.mydropzone.removeAllFiles()
      }
      this.files = []
    },
    save() {
      this.sending = true
      this.$emit('save', this.files)
    }
  }
}
</script>
<style lang="scss" scoped>
.vue-dropzone {
  background: #ececec;
  border-radius: 10px;
}
</style>
