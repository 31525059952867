<template>
  <div class="files__tab">
    <EmbedViewer ref="embed_viewer_dialog" :media="selected_media" />
    <VideoViewer
      ref="video_viewer_dialog"
      :media="selected_media"
      preview-from="files"
    />
    <ImageViewer
      ref="image_viewer_dialog"
      :media="selected_media"
      preview-from="files"
    />
    <DocsViewer ref="doc_viewer_dialog" :media="selected_media" />
    <IframeViewer ref="iframe_viewer_dialog" :media="selected_media" />
    <OtherViewer ref="other_viewer_dialog" :media="selected_media" />
    <DeleteDialog
      :open-dialog.sync="delete_dialog"
      title="Delete File"
      text-content="Are you sure you want to delete this file?"
      @delete="delete_item"
    />
    <ConfirmDialog
      title="Confirmation required"
      text-content="Share this file to this project?"
      :open-dialog.sync="confirmation"
      @confirm="shareTo"
      confirm-button-text="Yes, Share it"
    />
    <v-card
      outlined
      class="canva-content"
      min-height="500"
      v-if="canva_enabled"
    >
      <v-card-title class="py-1">
        <v-col>
          <h3 class="page-title">
            <v-icon color="primary" left>mdi-alpha-c-circle</v-icon>
            Canva
          </h3>
        </v-col>
        <v-spacer />
        <!--<v-col>
                    <v-text-field disabled solo flat outlined dense prepend-inner-icon="search" hide-details />
                </v-col>-->
      </v-card-title>
      <v-card-text>
        <v-card class="grid-view" flat>
          <v-container fluid grid-list-md>
            <v-layout wrap v-if="items.length">
              <v-col v-for="item in items" :key="item.id" xs="12" sm="4" md="3">
                <v-card elevation="2">
                  <Media
                    :height="150"
                    :media="item"
                    @click-alt="openViewer(item)"
                    @click-main="openViewer(item)"
                  />
                  <v-card-text>
                    <v-list dense class="pa-0">
                      <v-list-item two-line class="pa-0">
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <div
                              class="break-word-container"
                              :title="item.name"
                            >
                              {{ item.name | str_limit(25) }}
                            </div>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="caption"
                            v-if="item.custom_properties.message"
                          >
                            {{ item.custom_properties.message }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions>
                    <span class="overline">{{
                      item.custom_properties.ext
                    }}</span>
                    <v-spacer />
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="ml-1"
                          depressed
                          v-on="on"
                          @click="confirmShareTo(item)"
                        >
                          <v-icon>mdi-share-all-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Share to this {{ type | ucwords }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="ml-1"
                          v-on="on"
                          :href="item.download_url"
                          icon
                          depressed
                        >
                          <v-icon>mdi-cloud-download-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Download File</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="ml-1"
                          v-on="on"
                          @click="open_delete_dialog(item)"
                          icon
                          depressed
                        >
                          <v-icon>mdi-delete-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete File</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-layout>
            <v-row no-gutters v-if="items.length">
              <v-spacer />
              <v-card-actions class="py-2">
                <v-btn tile text v-if="noMoreData" disabled>NO MORE DATA</v-btn>
                <v-btn
                  tile
                  text
                  :loading="btnloading"
                  v-else
                  @click="get_more_files"
                >
                  LOAD MORE
                </v-btn>
              </v-card-actions>
              <v-spacer />
            </v-row>
            <Empty
              icon="mdi-file-alert-outline"
              headline="No file found"
              v-else
            />
          </v-container>
        </v-card>
      </v-card-text>
    </v-card>
    <v-card flat class="d-flex" v-else min-height="500">
      <Empty
        class="mx-auto align-self-center"
        headline="On development. Coming soon."
        icon="mdi-hammer-wrench"
      ></Empty>
    </v-card>
  </div>
</template>
<style lang="scss" scoped>
.files__tab {
  padding: 1.25rem;

  .canva-content {
    border-radius: 10px !important;
    border: 1px solid $borderLightGray;
  }

  .page-title {
    color: #223a6b !important;
    .v-icon {
      color: #223a6b !important;
    }
  }
}
</style>
<script>
import { list_functionality } from '@/services/list-functionality/list-functionality'
import * as apiTo from '@/modules/ProjectPreview-Files/tabs/FilesV2/api'
import EmbedViewer from '@/common/Viewer/EmbedViewer.vue'
import VideoViewer from '@/common/Viewer/VideoViewer.vue'
import ImageViewer from '@/common/Viewer/ImageViewer.vue'
import DocsViewer from '@/common/Viewer/DocsViewer.vue'
import IframeViewer from '@/common/Viewer/IframeViewer.vue'
import OtherViewer from '@/common/Viewer/OtherViewer.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import ConfirmDialog from '@/common/ConfirmDialog.vue'

export default {
  name: 'CanvaTab',
  mixins: [list_functionality],
  components: {
    ConfirmDialog,
    EmbedViewer,
    VideoViewer,
    ImageViewer,
    DocsViewer,
    IframeViewer,
    OtherViewer,
    DeleteDialog
  },
  props: {
    projectId: [Number, String]
  },
  data: () => ({
    canva_enabled: false,
    selected_media: null,
    confirmation: false,
    activeMedia: null
  }),
  computed: {
    type() {
      return this.$route.params.type || 'project'
    },
    paths() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          route: { name: 'default-content' }
        },
        {
          text: this.type,
          disabled: false,
          route: { path: `/dashboard/${this.type}/preview/${this.projectId}` }
        },
        { text: 'Canva', disabled: true, route: null }
      ]
    }
  },
  created() {
    if (this.canva_enabled) this.get_canva_files()
  },
  mounted() {
    this.$event.$emit('path-change', this.paths)
  },
  methods: {
    get_canva_files() {
      this.fill_table_via_url(`api/file/canva/files`)
    },
    get_more_files() {
      this.load_more_via_url(`api/file/canva/files`)
    },
    confirmShareTo(item) {
      this.activeMedia = item
      this.confirmation = true
    },
    shareTo() {
      apiTo
        .shareToProject({
          project_id: this.projectId,
          media_id: this.activeMedia.id
        })
        .then(({ data }) => {
          this.appSnackbar('Successfully shared media file!')
          this.confirmation = false
          this.activeMedia = null
        })
    },
    openViewer(media) {
      this.selected_media = media
      if (
        media.category === 'links' &&
        media.custom_properties.hasOwnProperty('embed') &&
        media.custom_properties.embed
      ) {
        this.$refs.embed_viewer_dialog.openDialog()
      } else if (media.category === 'videos') {
        this.$refs.video_viewer_dialog.openDialog()
      } else if (media.category === 'images') {
        this.$refs.image_viewer_dialog.openDialog()
      } else if (media.category === 'documents') {
        this.$refs.doc_viewer_dialog.openDialog()
      } else if (media.category === 'links') {
        this.$refs.iframe_viewer_dialog.openDialog()
      } else {
        this.$refs.other_viewer_dialog.openDialog()
      }
    },
    delete_item() {
      apiTo
        .deleteFile(this.delete_item_id)
        .then(() => {
          const indexFound = this.items.findIndex(
            (item) => item.id === this.delete_item_id
          )
          if (~indexFound) {
            this.items.splice(indexFound, 1)
            this.delete_item_id = null
            this.delete_dialog = false
            this.appSnackbar('File deleted!')
          }
        })
        .finally(() => this.$event.$emit('btnloading_off', false))
    }
  }
}
</script>
