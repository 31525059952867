<template>
  <v-card flat class="border-1 border-color--grey rounded-10">
    <v-card-title class="title-top-card" v-if="order">
      <span class="caption">{{ order.product.title | ucwords }}</span>
      <v-list-item-subtitle class="caption" v-if="order.product.description">
        {{ order.product.description }}
      </v-list-item-subtitle>
    </v-card-title>
    <v-carousel
      continuous
      cycle
      :show-arrows="false"
      hide-delimiters
      hide-delimiter-background
      height="250"
    >
      <v-carousel-item>
        <v-sheet color="transparent" height="100%" tile>
          <v-row class="fill-height" align="center" justify="center">
            <Attachment
              :item="attachment"
              prefer-thumb
              :styles="`max-height:100%;`"
            />
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-card-title class="title-bottom-card">
      <v-spacer />
      <v-btn icon :href="attachment.url" class="mr-1">
        <v-icon style="text-shadow: 1px 1px #fff">mdi-cloud-download</v-icon>
      </v-btn>
      <v-icon @click.stop="$emit('open-view')">mdi-eye-circle-outline</v-icon>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'OrderFileCard',
  props: {
    order: Object,
    attachment: Object
  }
}
</script>

<style scoped lang="scss">
.title-top-card {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 2;
  background: #f2f1f769;
}

.title-bottom-card {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 2;
  background: transparent;
}
</style>
