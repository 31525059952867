var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',_vm._b({staticClass:"rounded-5",attrs:{"elevation":hover ? 5 : 2,"min-height":"200","flat":"","color":"#cecece"}},'v-card',_vm.$attrs,false),[_c('v-card-text',{staticClass:"d-flex align-center justify-center"},[_c('v-img',{staticClass:"white--text align-start",attrs:{"contain":"","src":_vm.image,"height":"150"}},[_c('div',{staticClass:"fs-13 ml-1 primary--text flex-wrap fw-900"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")])])],1),(_vm.item)?_c('v-card-actions',{staticClass:"d-flex align-center justify-end"},[(_vm.is_folder)?_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('goto-folder', _vm.item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-folder-move")])],1)]}}],null,true)},[_c('span',[_vm._v("Open Folder")])]):_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.popupCenter({
                url: _vm.item.webViewLink,
                title: _vm.item.name,
                w: 900,
                h: 550
              })}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View File")])]),(_vm.item.exportLinks && _vm.canBeDownload)?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cloud-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download File")])])]}}],null,true)},[_c('v-list',{staticClass:"pa-0 ma-0"},_vm._l((_vm.links(_vm.item.exportLinks)),function(file,i){return _c('v-list-item',{key:i,staticClass:"ma-0 pa-0"},[_c('v-btn',{staticClass:"text-left",attrs:{"text":"","block":"","href":file.link}},[_vm._v(" Download as "+_vm._s(_vm._f("uppercase")(file.text))+" ")])],1)}),1)],1):(_vm.item.webContentLink && _vm.canBeDownload)?_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","href":_vm.item.webContentLink}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cloud-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download File")])]):_vm._e(),(_vm.canBeDelete)?_c('v-tooltip',{attrs:{"color":"error","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete-file')}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.is_folder ? 'mdi-folder-remove' : 'mdi-file-remove')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete "+_vm._s(_vm.is_folder ? 'Folder' : 'File'))])]):_vm._e()],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }