<template>
  <CustomDialog
    :open.sync="open"
    :title="title"
    :max-width="1366"
    @button1="close_dialog"
    :has-footer="false"
  >
    <template v-slot:content>
      <v-card flat v-if="order">
        <v-card-text class="pt-0 pb-2">
          <v-row dense class="fill-height">
            <v-col cols="8">
              <v-row dense class="fill-height">
                <v-col style="min-height: 450px" cols="12">
                  <v-btn
                    v-show="histories.length"
                    dark
                    :color="history_mode ? 'red' : 'success'"
                    :value="history_mode"
                    @click="history_mode = !history_mode"
                    class="switch ma-2 text-none"
                  >
                    {{ history_mode ? 'Hide History' : 'Show History' }}
                  </v-btn>
                  <div
                    class="fill-height border-1 border-color--grey rounded-10"
                    align="center"
                    justify="center"
                  >
                    <pinch-zoom
                      v-if="activeItem && is_image(activeItem)"
                      style="height: 100%; width: 100%"
                    >
                      <Attachment
                        animated
                        :item="activeItem"
                        :key="ckey"
                      ></Attachment>
                    </pinch-zoom>
                    <Attachment
                      animated
                      :item="activeItem"
                      :class="`mx-auto`"
                      v-else-if="activeItem"
                      :key="ckey"
                    />
                  </div>
                </v-col>
                <v-col
                  style="min-height: 120px"
                  cols="12"
                  v-if="!history_mode && order.orderresponses.length > 1"
                >
                  <carousel
                    :per-page="6"
                    class="border-1 fill-height border-color--grey rounded-10"
                    :pagination-enabled="false"
                    :navigation-enabled="true"
                    :per-page-custom="[
                      [500, 2],
                      [768, 5],
                      [1024, 6]
                    ]"
                  >
                    <slide
                      v-for="preview in order.orderresponses"
                      :key="preview.id"
                      class="pa-1 fill-height"
                      style="max-height: 100%"
                    >
                      <v-card height="100%" flat justify="center">
                        <Attachment
                          :item="preview"
                          class="ma-auto"
                        ></Attachment>
                        <v-card-title
                          @click.stop="setActive(preview)"
                          class="overlay"
                        ></v-card-title>
                      </v-card>
                    </slide>
                  </carousel>
                </v-col>
                <v-col
                  style="min-height: 120px"
                  cols="12"
                  v-if="history_mode && histories.length > 1"
                >
                  <carousel
                    :per-page="6"
                    class="border-1 fill-height border-color--grey rounded-10"
                    :pagination-enabled="false"
                    :navigation-enabled="true"
                    :per-page-custom="[
                      [500, 2],
                      [768, 5],
                      [1024, 6]
                    ]"
                  >
                    <slide
                      v-for="preview in histories"
                      :key="preview.id"
                      class="pa-1 fill-height"
                      style="max-height: 100%"
                    >
                      <v-card height="100%" flat justify="center">
                        <Attachment
                          animated
                          :item="preview"
                          :classes="`ma-auto`"
                        />
                        <v-card-title
                          @click.stop="setVersion(preview)"
                          class="overlay"
                        ></v-card-title>
                      </v-card>
                    </slide>
                  </carousel>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <div class="fill-height border-1 border-color--grey rounded-10">
                <v-card flat>
                  <v-toolbar elevation="0">
                    <v-toolbar-title class="title text--grey">
                      {{ order.product.title | ucwords }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list dense v-if="activeItem">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2">
                          {{ activeItem.filename }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="overline">
                          {{ activeItem.filetype }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                text
                                outlined
                                class="text-none"
                                v-bind="attrs"
                                v-on="on"
                                :href="activeItem.url"
                              >
                                <v-icon left>mdi-cloud-download</v-icon>
                                Download File
                              </v-btn>
                            </template>
                            <span>Download File</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                text
                                outlined
                                class="text-none ml-1"
                                v-bind="attrs"
                                v-on="on"
                                disabled
                              >
                                <v-icon left>mdi-share</v-icon>
                                Share
                              </v-btn>
                            </template>
                            <span>Share</span>
                          </v-tooltip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <Comment
                    :deletable="false"
                    class="mx-auto"
                    :with-avatar="false"
                    :fetch-api="fetchApi"
                    :component-key="ckey"
                    :add-api="addApi"
                  />
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import Comment from '@/common/Comment/Comment.vue'
import request from '@/services/axios_instance'

export default {
  name: 'AttachmentViewer',
  components: {
    Comment,
    CustomDialog
  },
  props: {
    order: Object,
    value: { type: Boolean, default: false },
    active: Object
  },
  data: () => ({
    open: false,
    ckey: 1,
    activeAttachment: null,
    activeVersion: null,
    histories: [],
    history_mode: false
  }),
  computed: {
    title() {
      return this.order ? this.order.product.title : ''
    },
    categories() {
      if (this.order.product.categories.length < 1) return '-'
      let categories = this.order.product.categories.map((i) => {
        return i.title
      })
      return categories.join(',')
    },
    fetchApi() {
      return this.activeAttachment && this.activeAttachment.pivot
        ? `api/comments/${this.activeAttachment.pivot.id}/order_response`
        : ''
    },
    addApi() {
      return this.activeAttachment && this.activeAttachment.pivot
        ? `api/comments/${this.activeAttachment.pivot.id}/order_response`
        : ''
    },
    activeItem() {
      if (this.history_mode && this.activeVersion) return this.activeVersion
      if (!this.history_mode && this.activeAttachment)
        return this.activeAttachment
      return null
    }
  },
  watch: {
    value(val) {
      this.open = val
    },
    open(val) {
      this.$emit('input', val)
    },
    active(val) {
      if (val) {
        this.activeAttachment = val
        this.ckey = val.id
        this.fetchHistory()
      }
    },
    order: {
      handler(val) {
        if (val) {
          if (!this.active)
            this.activeAttachment = val.orderresponses.length
              ? val.orderresponses[0]
              : null
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    close_dialog() {
      this.open = false
      this.activeItem = null
    },
    setActive(item) {
      this.activeAttachment = item
      this.ckey = item.id
      this.fetchHistory()
    },
    setVersion(item) {
      this.activeVersion = item
    },
    is_image(attachment) {
      return attachment && attachment.filetype.includes('image')
    },
    is_video(attachment) {
      return attachment && attachment.filetype.includes('video')
    },
    fetchHistory() {
      if (this.activeAttachment && this.activeAttachment.pivot) {
        request
          .get(
            `api/order-responses/${this.activeAttachment.pivot.id}/histories`
          )
          .then(({ data }) => {
            this.histories = data
            this.$nextTick(() => {
              if (this.histories.length) {
                this.activeVersion = this.histories[0]
                this.histories.push(this.activeAttachment)
              }
            })
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0px;
  cursor: pointer;
  background: transparent;
}

.switch {
  position: absolute;
  z-index: 5;
}
</style>
