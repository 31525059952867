<template>
  <v-card min-height="500" class="fullwidth overflow-hidden rounded-10" flat>
    <v-card-title class="d-flex">
      <!-- <AttachmentDropzone
        @save="handleBulkUpload"
        v-model="dropzone"
        class="fullwidth"
      ></AttachmentDropzone> -->
      <FileUploader
        @file-success="handleNewAddedFile"
        class="fullwidth"
        :model-id="projectId"
        model-type="project"
      ></FileUploader>
    </v-card-title>
    <v-card-text>
      <v-row dense align="center" justify="space-between">
        <div class="pa-5">
          <v-btn
            depressed
            height="40"
            color="primary"
            class="text-none mb-1 mr-2 subtitle-2"
            @click.stop="action_add_dialog(true)"
          >
            <v-icon left>mdi-link-plus</v-icon> ADD URL
          </v-btn>
        </div>
        <TableHeader :has-add-button="false">
          <template v-slot:prepends>
            <cMenu
              close-on-content-click
              :label="filter.iconText"
              left-icon=""
              height="40"
              elevation="0"
              color="primary"
              outlined
              btn-class="mr-1 btn-color-file"
            >
              <v-list dense class="">
                <v-list-item
                  v-for="item in filters"
                  :key="item.type"
                  @click="filter = item"
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon color="primary"> {{ item.icon }} </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="primary--text">
                      {{ item.iconText }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </cMenu>
          </template>
        </TableHeader>
      </v-row>
      <v-progress-linear
        color="primary"
        v-if="loading_items && view_type === 'grid'"
        height="10"
        class="my-1"
        indeterminate
      ></v-progress-linear>
      <VueTable
        v-if="view_type === 'list'"
        :items="filteredItems"
        class="files-main-section"
        :headers="headers"
        :show-row-actions="true"
        @load-more="loadMoreItems"
        icon="mdi-file-alert-outline"
        title="Files"
        style="border: none"
        :loading="loading_items"
        :no-more-data="!has_more_items"
        :show-select="user.is_admin"
        @delete-selected="action_bulk_delete_dialog(true, $event)"
        :show-top-header="false"
      >
        <template v-slot:row-slot="{ item }">
          <td width="15%">
            <v-avatar
              tile
              color="transparent"
              @click="action_view(item)"
              class="ma-1 cursor-pointer border-color--primary"
            >
              <v-img
                :lazy-src="settings.loader"
                :src="getAttachmentIcon(item)"
                contain
              ></v-img>
            </v-avatar>
          </td>
          <td class="text-center">{{ item.filesize | bytesToSize }}</td>
          <td width="15%" class="text-center">{{ item.filetype }}</td>
          <td class="text-center">
            {{ item.title || item.filename | ucwords }}
          </td>
          <td>
            <Avatar
              :user="item.uploader"
              v-if="item.uploader"
              icon-only
            ></Avatar>
            <v-avatar outline v-else>
              <v-icon>mdi-image</v-icon>
            </v-avatar>
          </td>
          <td>
            <FileMenu
              :file="item"
              :has-download="can_download(item)"
              :is-file-image-or-video="isImageOrVideo(item)"
              :has-modify="isImageOrVideo(item) && can_modify(item)"
              :has-approved="isImageOrVideo(item) && can_approved(item)"
              :has-reject="isImageOrVideo(item) && can_reject(item)"
              :has-edit="can_edit(item)"
              :has-delete="can_delete(item)"
              @view="action_view(item)"
              @delete="action_confirm_dialog(true, item)"
              @edit="action_edit_dialog(true, item)"
              @modification="update_status(item, 'modification')"
              @approved="update_status(item, 'approved')"
              @reject="update_status(item, 'reject')"
            ></FileMenu>
          </td>
        </template>
      </VueTable>
      <v-data-iterator
        v-else
        :items="filteredItems"
        item-key="id"
        :items-per-page="9999"
        hide-default-footer
      >
        <template v-slot:no-data>
          <Empty headline="No files found"></Empty>
        </template>
        <template v-slot:default="{ items }">
          <v-row dense>
            <v-col
              v-for="item in items"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <FileCard
                min-height="300"
                elevation="3"
                :is-file-image-or-video="isImageOrVideo(item)"
                :has-modify="isImageOrVideo(item) && can_modify(item)"
                :has-approved="isImageOrVideo(item) && can_approved(item)"
                :has-reject="isImageOrVideo(item) && can_reject(item)"
                :has-edit="can_edit(item)"
                :has-delete="can_delete(item)"
                @view="action_view(item)"
                @delete="action_confirm_dialog(true, item)"
                @edit="action_edit_dialog(true, item)"
                @modification="update_status(item, 'modification')"
                @approved="update_status(item, 'approved')"
                @reject="update_status(item, 'reject')"
                :file="item"
              ></FileCard>
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row dense align="center" justify="center">
            <v-btn
              depressed
              color="primary"
              class="text-none px-5 my-2"
              @click="loadMoreItems"
              :loading="loading_more_items"
              :disabled="!has_more_items || loading_more_items"
            >
              Load more
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>

    <EditFileInfoDialog
      v-model="edit_dialog"
      :item="activeItem"
      @update="handleUpdateFile"
    ></EditFileInfoDialog>

    <DeleteDialog
      v-model="confirm_dialog"
      @delete="handleDeleteFile"
      content="Are you sure you want to delete this file?<br>This cannot be undone!"
    ></DeleteDialog>

    <DeleteDialog
      v-model="bulk_delete_dialog"
      title="Delete Selected Files"
      text-content="Are you sure you want to delete these files? <br>This cannot be undone."
      @delete="handleBulkDeleteFiles"
    ></DeleteDialog>

    <LinkDialog
      v-model="add_dialog"
      @save="handleAddLink"
      :item="activeItem"
    ></LinkDialog>

    <ProjectFileViewerDialog
      v-model="view_dialog"
      :item="activeItem"
    ></ProjectFileViewerDialog>
  </v-card>
</template>

<script>
import AttachmentDropzone from '@/common/Dropzone/AttachmentDropzone.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import TableHeader from '@/common/TableHeader.vue'
import FileCard from '../../components/FileCard.vue'
import { mapGetters } from 'vuex'
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
import EditFileInfoDialog from '../../components/EditFileInfoDialog.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import LinkDialog from '@/modules/ProjectPreview-Files/components/LinkDialog.vue'
import ProjectFileViewerDialog from '@/modules/ProjectPreview-Files/components/ProjectFileViewerDialog.vue'
import FileUploader from '@/common/Uploaders/FileUploader.vue'
import FileMenu from '../../components/FileMenu.vue'
export default {
  mixins: [fetch_services],
  components: {
    AttachmentDropzone,
    VueTable,
    TableHeader,
    FileCard,
    EditFileInfoDialog,
    DeleteDialog,
    LinkDialog,
    ProjectFileViewerDialog,
    FileUploader,
    FileMenu
  },
  props: {
    projectId: { type: [Number, String], required: true }
  },
  data: () => ({
    dropzone: true,
    headers: [
      {
        text: 'Preview',
        value: 'thumbnail',
        sortable: false,
        width: 50
      },
      {
        text: 'File Size',
        value: 'filesize',
        align: 'center',
        sortable: false,
        width: 100
      },
      {
        text: 'File Type',
        value: 'filetype',
        align: 'center',
        sortable: false,
        width: 100
      },
      {
        text: 'File Name',
        value: 'filename',
        align: 'center',
        sortable: false
      },
      {
        text: 'Uploader',
        value: 'member',
        sortable: false
      },
      {
        text: 'Action',
        sortable: false,
        align: 'center',
        width: 50
      }
    ],
    filter: {
      id: 1,
      className: 'all',
      type: 'all',
      icon: 'mdi-file-cabinet',
      iconText: 'Filter'
    },
    filters: [
      {
        id: 1,
        className: 'all',
        type: 'all',
        icon: 'mdi-file-cabinet',
        iconText: 'All'
      },
      {
        id: 2,
        className: 'image',
        type: 'image',
        icon: 'mdi-file-image-outline',
        iconText: 'Image'
      },
      {
        id: 3,
        className: 'video',
        type: 'video',
        icon: 'mdi-file-video-outline',
        iconText: 'Video'
      },
      {
        id: 4,
        className: 'docs',
        type: 'document',
        icon: 'mdi-file-word-outline',
        iconText: 'Docs'
      },
      {
        id: 5,
        className: 'links',
        type: 'link',
        icon: 'mdi-file-link-outline',
        iconText: 'Links'
      },
      {
        id: 6,
        className: 'other',
        type: 'other',
        icon: 'mdi-file-table-outline',
        iconText: 'Other'
      },
      {
        id: 7,
        className: 'approval',
        type: 'approval',
        icon: 'mdi-file-check-outline',
        iconText: 'For Approval'
      }
    ]
  }),
  created() {
    this.loadItems(`api/projects/${this.projectId}/files?filter=all`)
  },
  computed: {
    ...mapGetters(['user', 'view_type']),
    ...mapGetters('project', ['managers', 'client']),
    filteredItems() {
      if (this.items.length === 0) return []
      if (this.filter.type === 'all') return this.items
      if (this.filter.type === 'approval') {
        return this.items.filter(
          (item) =>
            item.status === 'approved' &&
            (item.category === 'videos' || item.category === 'images')
        )
      }
      return this.items.filter((item) =>
        item.category.includes(this.filter.type)
      )
    },
    permissionsAll() {
      return this.$_permissions.get('hq_files')
    },
    permissionsOwn() {
      return this.$_permissions.get('hq_files_own')
    }
  },
  methods: {
    can_modify(file) {
      return this.user.is_admin || this.client.id === this.user.id
    },
    can_approved(file) {
      return (
        (this.user.is_admin || this.client.id === this.user.id) &&
        !['approved'].includes(file.status)
      )
    },
    can_reject(file) {
      return (
        (this.user.is_admin || this.client.id === this.user.id) &&
        !['rejected', 'approved'].includes(file.status)
      )
    },
    can_edit(file) {
      if(!this.user) return false
      if (this.user.is_admin) {
        return true
      }
      if (file.uploader && this.user.id === file.uploader.id) {
        return this.permissionsOwn.update
      }
      return this.permissionsAll.update
    },
    can_download(file) {
      return file.category !== 'links'
    },
    can_delete(file) {
       if(!this.user) return false
      if (this.user.is_admin) {
        return true
      }
      if (file.uploader && this.user.id === file.uploader.id) {
        return this.permissionsOwn.delete
      }
      return this.permissionsAll.delete
    },
    action_view(file) {
      if (file.category === 'links' && file.props && !file.props.embed) {
        window.open(file.props.url, '_blank').focus()
      } else {
        this.action_view_dialog(true, file)
      }
    },
    handleBulkUpload(files) {
      this.addItems(
        `api/projects/${this.projectId}/file-upload`,
        { attachments: files },
        'Files added',
        null,
        () => {
          this.$event.$emit('dropzone-reset', true)
        }
      )
    },
    handleDeleteFile() {
      this.deleteItem(
        `api/file/${this.activeItem.id}`,
        'File deleted',
        (data) => {
          this.action_confirm_dialog(false, null)
        },
        null
      )
    },
    handleUpdateFile(payload) {
      this.updateItem(
        `api/file/${this.activeItem.id}/info`,
        payload,
        'File updated',
        (data) => {
          this.action_edit_dialog(false, null)
        },
        null
      )
    },
    handleBulkDeleteFiles() {
      this.bulkDeleteItems(
        `api/projects/${this.projectId}/file/bulk-delete`,
        'Files deleted',
        () => {
          this.action_bulk_delete_dialog(false, [])
        },
        null
      )
    },
    handleAddLink(payload) {
      this.addItem(
        `api/projects/${this.projectId}/link`,
        payload,
        'File link added',
        (data) => {
          this.action_add_dialog(false)
        },
        null
      )
    },
    handleNewAddedFile(file) {
      this.insertItem(file)
    },
    update_status(item, status) {
      request
        .post(`api/file/${item.id}/status`, { status: status })
        .then(({ data }) => {
          let index = this.items.findIndex((i) => i.id === item.id)
          if (~index) {
            this.appSnackbar(data.message)
            this.items[index].status = status
          }
        })
    }
  }
}
</script>
<style>
.btn-color-file .v-btn__content .light--text {
  color: #223a6b !important;
}
</style>
<style lang="scss" scoped></style>
