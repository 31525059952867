<template>
  <CustomDialog
    ref="dialog"
    :open.sync="dialog"
    title="File URL"
    button1-text="Cancel"
    :button2-text="isEdit ? 'Update' : 'Save'"
    @button1="close_dialog"
    @button2="saveClicked"
    :main-btn-disabled="!valid"
  >
    <template v-slot:content>
      <v-form v-model="valid" ref="form">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <label class="subtitle-2 primary--text">File URL</label>
            <v-text-field
              class="tnx"
              v-model="url"
              type="url"
              outlined
              :rules="[urlRule(), requiredRule()]"
              placeholder="https://"
              clearable
              dense
              clear-icon="mdi-close-circle-outline"
              prepend-inner-icon="mdi-link-plus"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12">
            <label class="subtitle-2 primary--text">File Name</label>
            <v-text-field
              v-model="name"
              type="text"
              outlined
              placeholder="Untitled link (Optional)"
              clearable
              counter="100"
              clear-icon="mdi-close-circle-outline"
              prepend-inner-icon="mdi-link"
              hide-details="auto"
              dense
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </CustomDialog>
</template>

<script>
// Components
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    editMedia: Object
  },
  data: () => ({
    dialog: false,
    url: null,
    name: null,
    valid: false,
    btnloading: false
  }),
  watch: {
    value: {
      handler: function (val) {
        this.btnloading = false
        this.dialog = val
        if (!this.isEdit && val) {
          this.url = null
          this.name = null
          this.resetValidation()
        }
      },
      immediate: true
    },
    dialog(val) {
      this.$emit('input', val)
    },
    editMedia: {
      handler(val) {
        if (val && val.mime_type === 'link') {
          this.url = val.custom_properties.url
          this.name = val.file_name
        } else {
          this.url = null
          this.name = null
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    isEdit() {
      return this.editMedia ? true : false
    }
  },
  methods: {
    openDialog() {
      this.dialog = true
    },
    close_dialog() {
      this.dialog = false
    },
    closeAndClearDialog() {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    saveClicked() {
      if (!this.valid) return
      this.btnloading = true
      if (!this.isEdit) this.$emit('save', { url: this.url, title: this.name })
      else
        this.$emit('update', {
          url: this.url,
          title: this.name,
          id: this.editMedia.id
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.link-dialog-label {
  color: #223a6b !important;
  font-weight: 600 !important;
}
</style>
