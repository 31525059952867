<template>
    <v-card flat class="border-1 border-color--grey rounded-10">
        <v-card-title class="title-top-card">
            <span class="caption">{{ order.product.title | ucwords }}</span>
            <v-list-item-subtitle class="caption" v-if="order.product.description">
                {{ order.product.description }}
            </v-list-item-subtitle>
        </v-card-title>
        <v-carousel
                continuous cycle
                :show-arrows="order.orderresponses.length > 1" hide-delimiters hide-delimiter-background
                show-arrows-on-hover
                height="250"
        >
            <v-carousel-item
                    v-for="(image, i) in order.orderresponses"
                    :key="i"
            >
                <v-sheet color="transparent" height="100%" tile>
                    <v-row class="fill-height" align="center" justify="center">
                        <Attachment :item="image" prefer-thumb :styles="`max-height:100%;`" />
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
        <v-card-title class="title-bottom-card">
            <v-spacer />
            <v-icon @click.stop="$emit('open-view')">mdi-eye-circle-outline</v-icon>
        </v-card-title>
    </v-card>
</template>

<script>
    export default {
        name: "OrderCard",
        props: {
            order: Object
        }
    }
</script>

<style scoped lang="scss">
    .title-top-card {
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 2;
        background: #f2f1f769;
    }

    .title-bottom-card {
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 2;
        background: #f2f1f769;
    }
</style>