<template>
  <CustomDialog
    ref="dialog"
    :main-btn-disabled="isTabAdd ? !hasPreferredFolders : true"
    v-model="dialog"
    title="Manage Google Drive Folders"
    button1-text="Close"
    @button1="close_dialog"
    :has-button2="isTabAdd"
    button2-text="Save"
    @button2="saveClicked"
  >
    <template v-slot:content>
      <v-tabs v-model="tab">
        <v-tab class="text-none primary--text" href="#add"> Add Folders </v-tab>
        <v-tab class="text-none primary--text" href="#manage">
          Manage Existing Folders ({{ currentFolders.length || 0 }})
        </v-tab>
        <v-spacer />
        <div class="d-flex align-center justify-end">
          <span class="primary--text fw-900 mr-2">
            {{ futureTotal + '/' + maxFolderTotal }}
          </span>
          <HelpToolTip
            :text="`Maximum number of folders per project is ${maxFolderTotal}`"
          ></HelpToolTip>
        </div>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="add">
          <v-card min-height="350">
            <v-form ref="form" v-model="valid">
              <v-card-text>
                <v-row dense align="start" justify="space-between">
                  <v-col grow>
                    <label class="subtitle-2 primary--text">
                      Enter Google Drive Folder URL
                    </label>
                    <v-text-field
                      :disabled="maxFolderExceed"
                      v-model="url"
                      type="url"
                      clearable
                      @click:clear="resetValidation()"
                      placeholder="https://drive.google.com/drive/folders/FOLDER-ID"
                      :rules="[
                        requiredRule(),
                        gDriveFolderURLRule(),
                        urlRule()
                      ]"
                      outlined
                      hide-details="auto"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="rounded-10 text-none mt-6"
                        v-on="on"
                        height="40"
                        color="primary"
                        :disabled="maxFolderExceed || !valid"
                        @click="getFolderPreview"
                      >
                        <v-icon left> mdi-link-plus </v-icon> Fetch Folder
                      </v-btn>
                    </template>
                    <span>Fetch Folder</span>
                  </v-tooltip>
                </v-row>
                <Empty
                  v-if="!hasPreferredFolders"
                  headline="No folders yet.<br/> Enter the Google Drive folder URL above and fetch"
                  icon="mdi-folder"
                ></Empty>
                <v-sheet
                  v-for="folder in folders"
                  :key="folder.id"
                  elevation="2"
                  class="
                    fullwidth
                    rounded-5
                    d-flex
                    pa-3
                    align-center
                    justify-space-between
                    mb-2
                  "
                  color="#ececec"
                  min-height="80"
                >
                  <div class="d-flex align-center">
                    <v-img
                      class="mr-2"
                      height="50"
                      width="50"
                      contain
                      :src="folder_icon"
                    ></v-img>
                    <strong>{{ folder.name }}</strong>
                  </div>
                  <v-btn
                    @click="delete_from_preffered(folder)"
                    color="error"
                    depressed
                    small
                    class="text-none"
                  >
                    Remove Folder
                  </v-btn>
                </v-sheet>
              </v-card-text>
            </v-form>
          </v-card>
        </v-tab-item>
        <v-tab-item value="manage">
          <v-card min-height="350">
            <v-card-title>
              <p class="caption primary--text">
                <span class="error--text fw-700 mr-1">Notes: </span> Removing
                folder here in {{ settings.SiteName }} will not actually remove
                from Google Drive. <br />Some folders might be removed in Google
                Drive but exists here in {{ settings.SiteName }}. You can
                manually delete them here.
              </p>
            </v-card-title>
            <v-card-text>
              <v-sheet
                v-for="folder in currentFolders"
                :key="folder.id"
                elevation="2"
                class="
                  fullwidth
                  rounded-5
                  d-flex
                  pa-3
                  align-center
                  justify-space-between
                  mb-2
                "
                color="#ececec"
                min-height="80"
              >
                <div class="d-flex align-center">
                  <v-img
                    class="mr-2"
                    height="50"
                    width="50"
                    contain
                    :src="folder_icon"
                  ></v-img>
                  <strong>{{ folder.name }}</strong>
                  <HelpToolTip
                    class="mx-2"
                    icon="mdi-folder-alert"
                    icon-color="error"
                    text="This folder might be deleted in Google Drive!"
                    v-if="!folder.hasOwnProperty('trashed') || folder.trashed"
                  ></HelpToolTip>
                </div>
                <v-btn
                  @click="confirmDeleteFromDB(folder)"
                  color="error"
                  depressed
                  small
                  class="text-none"
                >
                  Remove Folder
                </v-btn>
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </CustomDialog>
</template>
<script>
import request from '@/services/axios_instance'
// Components
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  name: 'GDriveDialog',
  components: {
    CustomDialog
  },

  props: {
    value: { type: Boolean, default: false },
    projectId: { type: [String, Number], required: true },
    currentFolders: Array,
    maxFolderTotal: { type: Number, default: 20 }
  },

  data: () => ({
    tab: 'add',
    valid: false,
    dialog: false,
    url: null,
    hasChanges: false,
    folders: [],
    folder_icon: require('@/assets/file-icons/defaults/folder.png')
  }),

  computed: {
    isTabAdd() {
      return this.tab === 'add'
    },
    isTabManage() {
      return this.tab === 'manage'
    },
    hasPreferredFolders() {
      return this.folders.length > 0
    },
    futureTotal() {
      return this.currentFolders.length + this.folders.length
    },
    maxFolderExceed() {
      return this.futureTotal >= this.maxFolderTotal
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.dialog = val
        if (val) {
          this.hasChanges = false
          this.tab = 'add'
          this.url = null
          this.valid = false
          this.folders = []
          this.resetValidation()
        }
      },
      immediate: true
    },
    dialog: {
      handler: function (val) {
        this.$emit('input', val)
        if (!val && this.hasChanges) {
          this.$emit('current-folder-change', true)
        }
      },
      immediate: true
    }
  },
  methods: {
    close_dialog() {
      this.dialog = false
      this.resetValidation()
    },

    saveClicked() {
      if (!this.hasPreferredFolders) return
      this.$emit('save', { folders: this.folders })
    },

    getIdFrom() {
      let id = ''
      if (this.url) {
        let url = this.url
        let parts = url.split(
          /^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/
        )
        if (url.indexOf('?id=') >= 0) {
          return (id = parts[6].split('=')[1].replace('&usp', ''))
        } else {
          id = parts[5].split('/')
          //Using sort to get the id as it is the longest element.
          let sortArr = id.sort(function (a, b) {
            return b.length - a.length
          })
          return (id = sortArr[0])
        }
      }
    },

    isFolderDuplicate(id) {
      let index = this.currentFolders.findIndex((i) => i.id === id)
      return !!~index
    },

    getFolderPreview() {
      let id = this.getIdFrom()
      if (typeof id === 'undefined') {
        this.appSnackbar('Invalid URL', 'error')
        return
      }
      if (this.isFolderDuplicate(id)) {
        this.appSnackbar('Duplicate folder.', 'error')
        return
      }

      this.$gapi
        .request({
          path: 'https://www.googleapis.com/drive/v3/files/' + id,
          method: 'GET'
        })
        .catch((error) => {
          this.appSnackbar(error.result.error.message, 'error')
        })
        .then((response) => {
          if (
            response.result.mimeType &&
            response.result.mimeType.includes('.folder')
          ) {
            let index = this.folders.findIndex(
              (i) => i.id === response.result.id
            )
            if (~index) {
              this.appSnackbar('Duplicate Folder!', 'error')
            } else {
              this.folders.push(response.result)
              this.url = null
            }
          }
        })
    },

    delete_from_preffered(item) {
      let index = this.folders.findIndex((i) => i.id === item.id)
      if (~index) {
        this.folders.splice(index, 1)
      }
    },
    confirmDeleteFromDB(folder) {
      this.appConfirmation(
        `Remove this folder from this project? This will only remove from ${this.settings.SiteName} and not on Google Drive`,
        () => {
          this.delete_from_db(folder)
        }
      )
    },
    delete_from_db(item) {
      let long_id = item.hasOwnProperty('folder_id') ? item.folder_id : item.id
      request
        .delete(
          `api/projects/${this.projectId}/folder-id/google-drive/${long_id}`
        )
        .then(({ data }) => {
          let index = this.currentFolders.findIndex((i) => i.id === item.id)
          if (~index) {
            this.hasChanges = true
            this.currentFolders.splice(index, 1)
            this.appSnackbar('Folder removed successfully')
          }
        })
    }
  }
}
</script>
