<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    title="Update File Info"
    button2-text="Save"
    @button2="save"
    :main-btn-disabled="!valid"
    :max-width="600"
  >
    <template v-slot:content>
      <v-form v-model="valid">
        <v-row dense>
          <v-col cols="12">
            <label class="primary--text subtitle-2"> Title * </label>
            <v-text-field
              v-model="title"
              outlined
              clearable
              clear-icon="mdi-close-circle-outline"
              dense
              placeholder="Enter Title"
              hide-details="auto"
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <label class="primary--text subtitle-2"> Description </label>
            <v-textarea
              v-model="description"
              outlined
              clearable
              placeholder="Enter Description "
              clear-icon="mdi-close-circle-outline"
              dense
              auto-grow
              rows="3"
              hide-details="auto"
            ></v-textarea>
          </v-col>
          <v-col cols="12" v-if="isLinkType">
            <label class="primary--text subtitle-2"> URL * </label>
            <v-text-field
              v-model="url"
              outlined
              type="url"
              clearable
              clear-icon="mdi-close-circle-outline"
              dense
              placeholder="https://"
              hide-details="auto"
              :rules="[requiredRule(), urlRule()]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  name: 'EditFileInfoDialog',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object }
  },
  data: () => ({
    dialog: false,
    valid: false,
    url: null,
    title: null,
    description: null
  }),
  computed: {
    isLinkType() {
      return this.item && this.item.category === 'links'
    }
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    },
    item: {
      handler: function (val) {
        if (val) {
          this.url = val.link_url || null
          this.title = val.title || val.filename
          this.description = val.description || null
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    save() {
      if (this.valid) {
        this.$emit('update', {
          url: this.url,
          title: this.title,
          description: this.description
        })
      }
    }
  }
}
</script>
<style></style>
