<template>
  <v-card outlined flat v-bind="$attrs" class="rounded-10 overflow-hidden">
    <v-overlay absolute :value="uploading && files_count > 0">
      <div class="d-flex align-center justify-center flex-column">
        <h3>Uploading... Please wait...</h3>
        <v-icon :size="50">mdi-spin mdi-loading</v-icon>
        <v-btn
          depressed
          class="text-none"
          small
          color="error"
          @click="disableDropzone"
        >
          Cancel Uploading
        </v-btn>
      </div>
    </v-overlay>
    <VueDropzone
      ref="myVueDropzone"
      id="myVueDropzone"
      use-custom-slot
      :options="dropzoneOptions"
      @vdropzone-file-added="fileAddedEvent"
      @vdropzone-sending="sendingEvent"
      @vdropzone-error="errorEvent"
      @vdropzone-removed-file="removedFileEvent"
      @vdropzone-complete="completeEvent"
      @vdropzone-queue-complete="queueCompleteEvent"
    >
      <div class="dropzone-custom-content">
        <v-row dense align="center" justify="center">
          <v-col class="pa-2">
            <v-icon color="primary" size="80">mdi-cloud-upload</v-icon>
            <h3 class="subtitle-1 primary--text">
              Drag and drop to upload files!
            </h3>
            <div class="subtitle-2 primary--text">
              ...or click to select a file from your device
            </div>
          </v-col>
        </v-row>
      </div>
    </VueDropzone>
    <v-card-actions
      style="background: #e5e5e5"
      class="d-flex align-center justify-center"
    >
      <v-btn
        small
        @click="processQueue"
        v-if="!autoUpload"
        depressed
        :disabled="files_count < 1"
        class="px-5 text-none"
        color="primary"
      >
        Start Upload
        <v-icon right>mdi-play</v-icon>
      </v-btn>
      <v-btn
        @click="removeAllFiles"
        small
        :disabled="files_count < 1"
        depressed
        class="px-5 text-none"
        color="primary"
      >
        Clear All
        <v-icon right>mdi-delete</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'FileUploader',
  inheritAttrs: false,
  components: { VueDropzone: vue2Dropzone },
  props: {
    autoUpload: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    extraParams: { type: Object, default: () => {} },
    acceptedFiles: { type: String },
    modelId: { type: [String, Number], default: null },
    modelType: { type: [String], default: null }
  },
  created() {
    this.session_id = uuidv4()
    this.removeAllFiles()
  },
  data: () => ({
    session_id: null,
    files_count: [],
    uploading: false
  }),
  watch: {
    files_count: {
      handler: function (val) {
        if (val < 1) {
          this.uploading = false
        }
      },
      immediate: true
    }
  },
  computed: {
    dropzoneOptions() {
      return {
        url: `${this.settings.apiHostBaseURL}/api2/attachments/dropzone-chunks`,
        thumbnailWidth: 180,
        addRemoveLinks: true,
        headers: {
          Authorization: 'Bearer ' + this.settings.token()
        },
        method: 'POST',
        maxFiles: 10,
        maxFilesize: 1024, //in megabytes
        chunking: true,
        forceChunking: true,
        timeout: 50000,
        chunkSize: 10000000, //10mb in bytes
        retryChunks: true,
        retryChunksLimit: 3,
        autoProcessQueue: false,
        clickable: !this.disabled,
        acceptedFiles: this.acceptedFiles
      }
    }
  },
  methods: {
    processQueue() {
      if (!this.$refs.myVueDropzone) return
      this.uploading = true
      this.$refs.myVueDropzone.processQueue()
    },
    removeAllFiles() {
      if (!this.$refs.myVueDropzone) return
      this.$refs.myVueDropzone.removeAllFiles()
      this.files_count = 0
    },
    removeFile(file) {
      if (!this.$refs.myVueDropzone) return
      this.$refs.myVueDropzone.removeFile(file)
    },
    disableDropzone() {
      if (!this.$refs.myVueDropzone) return
      this.$refs.myVueDropzone.disable()
      this.$nextTick(() => {
        this.enableDropzone()
      })
    },
    enableDropzone() {
      if (!this.$refs.myVueDropzone) return
      this.$refs.myVueDropzone.enable()
    },
    sendingEvent(file, xhr, formData) {
      formData.append('file_upload_session', this.session_id)
      formData.append('bind_model_id', this.modelId)
      formData.append('bind_model_type', this.modelType)
    },
    errorEvent(file, message, xhr) {
      this.appSnackbar(`Error: ${file.name} ${message}`, 'error')
    },
    fileAddedEvent(file) {
      this.files_count++
    },
    removedFileEvent(file, error, xhr) {
      this.files_count--
    },
    completeEvent(file) {
      if (file.status === 'success' && file.hasOwnProperty('xhr') && file.xhr) {
        this.$emit('file-success', JSON.parse(file.xhr.response))
        this.appSnackbar(`File(s) uploaded.`)
      }
      this.removeFile(file)
    },
    queueCompleteEvent() {
      this.session_id = uuidv4()
    }
  }
}
</script>

<style lang="scss">
#myVueDropzone.dropzone {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;

  .dz-message {
    width: 100%;
  }
  .dz-preview {
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;

    &.dz-image-preview {
      width: 180px;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      .dz-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
