var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomDialog',{attrs:{"open":_vm.open,"title":_vm.title,"max-width":1366,"has-footer":false},on:{"update:open":function($event){_vm.open=$event},"button1":_vm.close_dialog},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.order)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-0 pb-2"},[_c('v-row',{staticClass:"fill-height",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',{staticClass:"fill-height",attrs:{"dense":""}},[_c('v-col',{staticStyle:{"min-height":"450px"},attrs:{"cols":"12"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.histories.length),expression:"histories.length"}],staticClass:"switch ma-2 text-none",attrs:{"dark":"","color":_vm.history_mode ? 'red' : 'success',"value":_vm.history_mode},on:{"click":function($event){_vm.history_mode = !_vm.history_mode}}},[_vm._v(" "+_vm._s(_vm.history_mode ? 'Hide History' : 'Show History')+" ")]),_c('div',{staticClass:"fill-height border-1 border-color--grey rounded-10",attrs:{"align":"center","justify":"center"}},[(_vm.activeItem && _vm.is_image(_vm.activeItem))?_c('pinch-zoom',{staticStyle:{"height":"100%","width":"100%"}},[_c('Attachment',{key:_vm.ckey,attrs:{"animated":"","item":_vm.activeItem}})],1):(_vm.activeItem)?_c('Attachment',{key:_vm.ckey,class:"mx-auto",attrs:{"animated":"","item":_vm.activeItem}}):_vm._e()],1)],1),(!_vm.history_mode && _vm.order.orderresponses.length > 1)?_c('v-col',{staticStyle:{"min-height":"120px"},attrs:{"cols":"12"}},[_c('carousel',{staticClass:"border-1 fill-height border-color--grey rounded-10",attrs:{"per-page":6,"pagination-enabled":false,"navigation-enabled":true,"per-page-custom":[
                    [500, 2],
                    [768, 5],
                    [1024, 6]
                  ]}},_vm._l((_vm.order.orderresponses),function(preview){return _c('slide',{key:preview.id,staticClass:"pa-1 fill-height",staticStyle:{"max-height":"100%"}},[_c('v-card',{attrs:{"height":"100%","flat":"","justify":"center"}},[_c('Attachment',{staticClass:"ma-auto",attrs:{"item":preview}}),_c('v-card-title',{staticClass:"overlay",on:{"click":function($event){$event.stopPropagation();return _vm.setActive(preview)}}})],1)],1)}),1)],1):_vm._e(),(_vm.history_mode && _vm.histories.length > 1)?_c('v-col',{staticStyle:{"min-height":"120px"},attrs:{"cols":"12"}},[_c('carousel',{staticClass:"border-1 fill-height border-color--grey rounded-10",attrs:{"per-page":6,"pagination-enabled":false,"navigation-enabled":true,"per-page-custom":[
                    [500, 2],
                    [768, 5],
                    [1024, 6]
                  ]}},_vm._l((_vm.histories),function(preview){return _c('slide',{key:preview.id,staticClass:"pa-1 fill-height",staticStyle:{"max-height":"100%"}},[_c('v-card',{attrs:{"height":"100%","flat":"","justify":"center"}},[_c('Attachment',{attrs:{"animated":"","item":preview,"classes":"ma-auto"}}),_c('v-card-title',{staticClass:"overlay",on:{"click":function($event){$event.stopPropagation();return _vm.setVersion(preview)}}})],1)],1)}),1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"fill-height border-1 border-color--grey rounded-10"},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"title text--grey"},[_vm._v(" "+_vm._s(_vm._f("ucwords")(_vm.order.product.title))+" ")])],1),(_vm.activeItem)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.activeItem.filename)+" ")]),_c('v-list-item-subtitle',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.activeItem.filetype)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"text":"","outlined":"","href":_vm.activeItem.url}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cloud-download")]),_vm._v(" Download File ")],1)]}}],null,false,2630433729)},[_c('span',[_vm._v("Download File")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none ml-1",attrs:{"text":"","outlined":"","disabled":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-share")]),_vm._v(" Share ")],1)]}}],null,false,1378798099)},[_c('span',[_vm._v("Share")])])],1)],1)],1)],1):_vm._e(),_c('v-divider'),_c('Comment',{staticClass:"mx-auto",attrs:{"deletable":false,"with-avatar":false,"fetch-api":_vm.fetchApi,"component-key":_vm.ckey,"add-api":_vm.addApi}})],1)],1)])],1)],1)],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }