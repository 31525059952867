<template>
  <v-menu :close-on-content-click="false" :nudge-width="200" offset-y top left>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon color="primary" class="action insert__emoticon"> insert_emoticon </v-icon>
      </v-btn>
    </template>
    <VEmojiPicker v-show="true" label-search="Search" @select="onSelectEmoji" />
  </v-menu>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";

export default {
  components: {
    VEmojiPicker,
  },
  methods: {
    onSelectEmoji(emoji) {
      this.$emit("emoji-selected", emoji);
    },
  },
};
</script>
