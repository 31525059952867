<template>
  <v-menu left bottom>
    <template v-slot:activator="{ on: menu }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon v-on="{ ...tooltip, ...menu }" depressed>
            <v-icon color="primary"> mdi-dots-vertical-circle-outline </v-icon>
          </v-btn>
        </template>
        <span>More Actions</span>
      </v-tooltip>
    </template>
    <v-list dense>
      <v-list-item @click.stop="action_view">
        <v-list-item-title class="primary--text">
          <v-icon left color="primary">mdi-eye</v-icon>
          View File
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="hasEdit" @click.stop="action_edit">
        <v-list-item-title class="primary--text">
          <v-icon left color="primary">mdi-pencil</v-icon>
          Edit File Info
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="hasDownload" @click.stop="action_download">
        <v-list-item-title class="primary--text">
          <v-icon left color="primary">mdi-cloud-download</v-icon>
          Download File
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="hasDownload" @click.stop="action_copy_link">
        <v-list-item-title class="primary--text">
          <v-icon left color="primary">mdi-file-link</v-icon>
          Copy File Link
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="file.category === 'links'"
        @click.stop="action_open_link"
      >
        <v-list-item-title class="primary--text">
          <v-icon left color="primary">mdi-open-in-new</v-icon>
          Open link
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="hasDelete" @click.stop="action_delete">
        <v-list-item-title class="primary--text">
          <v-icon left color="primary">mdi-delete</v-icon>
          Delete File
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isFileImageOrVideo" class="primary">
        <v-list-item-title class="white--text">
          Status: {{ file.status | ucwords }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="action_modification"
        v-if="isFileImageOrVideo && hasModify"
      >
        <v-list-item-title class="primary--text">
          <v-icon left color="primary">mdi-file-edit-outline</v-icon>
          For Modification
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="action_approved"
        v-if="isFileImageOrVideo && hasApproved"
      >
        <v-list-item-title class="primary--text">
          <v-icon left color="primary">mdi-file-check-outline</v-icon>
          Approved
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="action_reject"
        v-if="isFileImageOrVideo && hasReject"
      >
        <v-list-item-title class="primary--text">
          <v-icon left color="primary"> mdi-file-cancel-outline </v-icon>
          Reject
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import copy from 'copy-to-clipboard'
export default {
  name: 'FileMenu',
  props: {
    hasDownload: { type: Boolean, default: false },
    hasModify: { type: Boolean, default: false },
    hasApproved: { type: Boolean, default: false },
    hasReject: { type: Boolean, default: false },
    hasEdit: { type: Boolean, default: false },
    hasDelete: { type: Boolean, default: false },
    isFileImageOrVideo: { type: Boolean, default: false },
    file: { type: Object, required: true }
  },
  computed: {
    //
  },
  methods: {
    action_download() {
      let open = window.open(this.file.url, '_blank')
      open.focus()
    },
    action_copy_link() {
      if (copy(this.file.url)) {
        this.appSnackbar('File link copied!')
      } else {
        this.appSnackbar(
          'Copy to clipboard not supported on this browser!',
          'error'
        )
      }
    },
    action_open_link() {
      this.popupCenter({
        url: this.file.link_url,
        title: this.file.title,
        w: 900,
        h: 550
      })
    },
    action_view() {
      if (this.file.category === 'links' && !this.file.embed) {
        this.action_open_link()
      } else {
        this.$emit('view')
      }
    },
    action_edit() {
      this.$emit('edit')
    },
    action_delete() {
      this.$emit('delete')
    },
    action_modification() {
      this.$emit('modification')
    },
    action_approved() {
      this.$emit('approved')
    },
    action_reject() {
      this.$emit('reject')
    }
  }
}
</script>

<style></style>
