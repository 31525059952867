<template>
  <div class="files__tab">
    <v-card flat outlined class="offers-content" min-height="500">
      <v-card-title class="py-1">
        <v-col>
</v-col>
        <v-spacer />
        <div class="mt-4">
          <v-select
            :items="filter"
            label="Filter"
            dense
            style="width: 150px"
            outlined
          ></v-select>
        </div>
        <!-- <v-btn-toggle
          dense
          mandatory
          v-model="filter"
          v-show="view_by === `by_files`"
        >
          <v-btn
            active-class="primary light--text"
            value="all"
            class="text-none px-3"
          >
            All
          </v-btn>
          <v-btn
            active-class="primary light--text"
            value="image"
            class="text-none px-3"
          >
            Images
          </v-btn>
          <v-btn
            active-class="primary light--text"
            value="video"
            class="text-none px-3"
          >
            Videos
          </v-btn>
        </v-btn-toggle>
        <v-btn-toggle dense mandatory class="ml-1" v-model="view_by">
          <v-btn
            active-class="primary light--text"
            value="by_orders"
            class="text-none px-5"
          >
            <v-icon left small>mdi-cart</v-icon>
            View by Orders
          </v-btn>
          <v-btn
            active-class="primary light--text"
            value="by_files"
            class="text-none px-5"
          >
            <v-icon left small>mdi-file</v-icon>
            View by Files
          </v-btn>
        </v-btn-toggle> -->
      </v-card-title>
      <v-card-text>
        <v-progress-linear
          color="primary"
          v-if="loading"
          indeterminate
        ></v-progress-linear>
        <v-container fluid grid-list-md>
          <v-layout wrap v-if="items.length">
            <template v-if="view_by === 'by_orders'">
              <v-col
                v-for="order in items"
                :key="order.id"
                xs="12"
                sm="4"
                md="3"
              >
                <OrderCard
                  :order="order"
                  @open-view="openViewer(order, null)"
                />
              </v-col>
            </template>
            <template v-else>
              <template v-for="order in items">
                <template v-for="attachment in filtered(order.orderresponses)">
                  <v-col :key="attachment.id" xs="12" sm="4" md="3">
                    <OrderFileCard
                      :order="order"
                      :attachment="attachment"
                      @open-view="openViewer(order, attachment)"
                    />
                  </v-col>
                </template>
              </template>
            </template>
          </v-layout>
          <Empty v-else headline="No order yet" icon="mdi-cart-off" />
        </v-container>

        <v-row dense>
          <v-spacer />
          <v-btn
            :disabled="loading || !next_page_url"
            block
            depressed
            text
            class="text-none"
            :loading="loading"
            @click="getMoreOrdersFiles"
          >
            {{ loading || !next_page_url ? "No more" : "Load more" }}
          </v-btn>
          <v-spacer />
        </v-row>
      </v-card-text>
    </v-card>
    <AttachmentViewer
      :order="activeOrder"
      :active="activeAttachment"
      v-model="viewer_dialog"
    />
  </div>
</template>
<style lang="scss" scoped>

.files__tab {
  padding: 1.25rem;

  .offers-content {
    border-radius: 10px !important;
    border: 1px solid $borderLightGray;
  }

  .page-title .v-icon {
    color: $textDark;
  }
}
</style>
<script>
import request from "@/services/axios_instance";
import OrderFileCard from "../../components/OrderFileCard.vue";
import AttachmentViewer from "../../components/AttachmentViewer.vue";
import OrderCard from "../../components/OrderCard.vue";

export default {
  name: "OffersTab",
  components: {
    OrderCard,
    AttachmentViewer,
    OrderFileCard,
  },
  props: {
    projectId: [Number, String],
  },
  data: () => ({
    loading: false,
    filter: "all",
    view_by: "by_files",
    items: [],
    next_page_url: null,
    viewer_dialog: false,
    activeOrder: null,
    activeAttachment: null,
    filter: ['All', 'Images', 'Videos', 'By Orders', 'By Files']
  }),
  computed: {
    type() {
      return this.$route.params.type || "project";
    },
    paths() {
      return [
        {
          text: "Dashboard",
          disabled: false,
          route: { name: "default-content" },
        },
        {
          text: this.type,
          disabled: false,
          route: { path: `/dashboard/${this.type}/preview/${this.projectId}` },
        },
        { text: "Offers", disabled: true, route: null },
      ];
    },
  },
  watch: {
    filter(val) {
      this.getOrdersFiles();
    },
    view_by(val) {
      this.getOrdersFiles();
    },
  },
  mounted() {
    this.$event.$emit("path-change", this.paths);
    this.getOrdersFiles();
  },
  methods: {
    getOrdersFiles() {
      this.loading = true;
      request
        .get(
          `api/project-orders/${this.projectId}?filter=${this.filter}&view=${this.view_by}`
        )
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    getMoreOrdersFiles() {
      this.loading = true;
      request
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    openViewer(order, attachment) {
      this.activeOrder = order;
      this.activeAttachment = attachment;
      this.viewer_dialog = true;
    },
    filtered(array) {
      if (this.filter === "all") return array;
      return array.filter((item) => item.filetype.includes(this.filter));
    },
  },
};
</script>