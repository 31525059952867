<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      class="rounded-5"
      :elevation="hover ? 5 : 2"
      min-height="200"
      flat
      color="#cecece"
      v-bind="$attrs"
    >
      <v-card-text class="d-flex align-center justify-center">
        <v-img
          contain
          :src="image"
          class="white--text align-start"
          height="150"
        >
          <div class="fs-13 ml-1 primary--text flex-wrap fw-900">
            {{ item.name }}
          </div>
        </v-img>
      </v-card-text>
      <v-card-actions class="d-flex align-center justify-end" v-if="item">
        <v-tooltip color="primary" top v-if="is_folder">
          <template v-slot:activator="{ on }">
            <v-btn
              depressed
              icon
              color="primary"
              v-on="on"
              @click="$emit('goto-folder', item)"
            >
              <v-icon color="primary">mdi-folder-move</v-icon>
            </v-btn>
          </template>
          <span>Open Folder</span>
        </v-tooltip>
        <v-tooltip color="primary" top v-else>
          <template v-slot:activator="{ on }">
            <v-btn
              depressed
              icon
              color="primary"
              v-on="on"
              @click="
                popupCenter({
                  url: item.webViewLink,
                  title: item.name,
                  w: 900,
                  h: 550
                })
              "
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>View File</span>
        </v-tooltip>

        <v-menu
          bottom
          v-if="item.exportLinks && canBeDownload"
          origin="center center"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon v-on="{ ...tooltip, ...menu }">
                  <v-icon color="primary">mdi-cloud-download</v-icon>
                </v-btn>
              </template>
              <span>Download File</span>
            </v-tooltip>
          </template>
          <v-list class="pa-0 ma-0">
            <v-list-item
              v-for="(file, i) in links(item.exportLinks)"
              :key="i"
              class="ma-0 pa-0"
            >
              <v-btn text block :href="file.link" class="text-left">
                Download as {{ file.text | uppercase }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-tooltip
          color="primary"
          top
          v-else-if="item.webContentLink && canBeDownload"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :href="item.webContentLink">
              <v-icon color="primary">mdi-cloud-download</v-icon>
            </v-btn>
          </template>
          <span>Download File</span>
        </v-tooltip>

        <v-tooltip color="error" top v-if="canBeDelete">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="$emit('delete-file')">
              <v-icon color="primary">
                {{ is_folder ? 'mdi-folder-remove' : 'mdi-file-remove' }}
              </v-icon>
            </v-btn>
          </template>
          <span>Delete {{ is_folder ? 'Folder' : 'File' }}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'GoogleDriveCard',
  props: {
    item: { type: Object, required: true }
  },
  data: () => ({
    reveal: false
  }),
  computed: {
    is_folder() {
      return this.item && this.item.mimeType.includes('.folder') ? true : false
    },
    canBeDownload() {
      return this.item && this.item.capabilities.canDownload ? true : false
    },
    canBeDelete() {
      return this.item && this.item.capabilities.canDelete ? true : false
    },
    image() {
      if (!this.item || this.item.mimeType.includes('.folder')) {
        return require('@/assets/file-icons/defaults/folder.png')
      }
      return this.item.hasThumbnail
        ? this.item.thumbnailLink
        : this.item.iconLink
    }
  },
  methods: {
    links(object) {
      let arr = Object.values(object)
      return arr.map((i) => ({
        text: i.split('=').pop(),
        link: i
      }))
    }
  }
}
</script>

<style></style>
