<template>
  <v-row dense no-gutters align="center" class="chat-field px-1">
    <v-col
      md="12"
      sm="12"
      cols="12"
      class="py-1 px-2 d-flex align-center justify-start"
      v-if="singleFile && file"
    >
      <span class="caption primary--text fw-500">Attachment :</span>
      <v-chip
        dense
        small
        close
        label
        outlined
        class="mx-1 px-5 success--text"
        @click:close="removeFile(file, 0)"
      >
        <v-icon color="success" left>mdi-file</v-icon>
        {{ file.name | trunc_mid(15, 15) }}
      </v-chip>
    </v-col>
    <v-col
      md="12"
      sm="12"
      cols="12"
      class="py-1 px-2 d-flex align-center justify-start"
      v-if="!singleFile && files.length > 0"
    >
      <span class="caption primary--text fw-500">Attachments :</span>
      <v-chip
        dense
        small
        close
        label
        outlined
        v-for="(file, inx) in files"
        :key="inx"
        class="mx-1 px-5 success--text"
        @click:close="removeFile(file, inx)"
      >
        <v-icon color="success" left>mdi-file</v-icon>
        {{ file.name | trunc_mid(15, 15) }}
      </v-chip>
    </v-col>
    <v-col class="send-btn-icons d-flex align-center" v-if="!small">
      <v-btn icon class="action ml-1" @click="$refs.hidden_input.click()">
        <v-icon color="primary" size="30px">mdi-attachment</v-icon>
      </v-btn>
      <v-btn icon class="action emoji-chat-picker ml-1" color="primary">
        <EmojiPicker
          @emoji-selected="appendEmoji"
          style="font-size: 30px !important"
        />
      </v-btn>
    </v-col>
    <v-col class="textfield--wrapper">
      <input
        type="file"
        ref="hidden_input"
        @change="file_selected"
        class="hidden-input d-none"
      />
      <AtTa
        :members="mentionables"
        v-model="message"
        name-key="username"
        :allow-spaces="false"
      >
        <template slot="item" slot-scope="{ item }">
          <img :src="item.image_url" class="mention-img" />
          <span v-text="item.fullname" />
        </template>
        <textarea
          class="write__msg"
          :placeholder="placeholder"
          :rows="1"
          style="
            color: grey;
            resize: none;
            overflow-y: hidden;
            outline: none;
            border: none !important;
            align-self: center;
            padding-top: 10px;
          "
          @keydown.exact="$emit('typing')"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="sendMessage"
          @keydown.enter.shift.exact="newline"
        />
      </AtTa>
    </v-col>
    <template v-if="!small" class="test">
      <v-btn
        :disabled="disabled"
        :loading="btnsending"
        icon
        @click="sendMessage(message)"
        class="action ml-1"
      >
        <v-icon color="primary"> mdi-send </v-icon>
      </v-btn>
    </template>
    <template v-else>
      <v-menu top left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="test">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-card dense flat>
          <v-card-text class="py-1">
            <EmojiPicker @emoji-selected="appendEmoji" />
            <v-btn depressed icon @click="$refs.hidden_input.click()">
              <v-icon>mdi-attachment</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-btn
        depressed
        :disabled="disabled"
        :loading="btnsending"
        class="test"
        icon
        @click="sendMessage"
        style="trasform: rotate(-45deg) !important"
      >
        <v-icon color="primary" class="123"> mdi-send </v-icon>
      </v-btn>
    </template>
  </v-row>
</template>

<script>
import EmojiPicker from '@/common/EmojiPicker.vue'
import AtTa from 'vue-at/dist/vue-at-textarea'

export default {
  components: {
    EmojiPicker,
    AtTa
  },
  props: {
    mentionables: { type: Array, default: () => [] },
    small: { type: Boolean, default: false },
    singleFile: { type: Boolean, default: true },
    sending: { type: Boolean, default: false }
  },
  data: () => ({
    message: '',
    btnsending: false,
    files: [],
    file: null,
    fileLimit: 1
  }),
  watch: {
    sending: {
      handler: function (val) {
        this.btnsending = val
        if (!val) {
          this.btnsending = false
          this.message = ''
          this.files = []
          this.file = null
        }
      },
      immediate: true
    }
  },
  created() {
    this.$event.$on('btnsending_off', (status) => {
      this.btnsending = false
      this.message = ''
      this.files = []
      this.file = null
    })
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user
    },
    disabled() {
      return (
        this.btnsending || (this.message.trim() === '' && this.isEmptyFiles)
      )
    },
    isEmptyFiles() {
      if (!this.singleFile) return this.files.length === 0
      return this.file ? false : true
    },
    placeholder() {
      return this.small ? 'Write something...' : 'Type a message...@name...'
    }
  },
  methods: {
    newline() {
      this.message = `${this.message}\n`
    },
    sendMessage() {
      if (
        this.btnsending ||
        (this.message.trim() === '' && this.isEmptyFiles)
      ) {
        this.appSnackbar('Message cannot be empty.', 'error')
        return
      }
      this.btnsending = true
      if (this.singleFile)
        this.$emit('send-message', { message: this.message, file: this.file })
      else
        this.$emit('send-message', { message: this.message, files: this.files })
    },

    appendEmoji(emoji) {
      if (this.message) {
        this.message += emoji.data
      } else {
        this.message = emoji.data
      }
    },
    removeFile(item, inx) {
      if (this.singleFile) {
        this.file = null
      } else {
        this.files.splice(inx, 1)
      }
    },
    file_selected(event) {
      if (event.target.files && event.target.files[0]) {
        this.files = []
        if (
          event.target.files[0].size >= this.settings.fileSizeMaxUploadLimit
        ) {
          this.appSnackbar(
            `File size should not exceed ${this.settings.fileSizeMaxUploadInMB}MB`,
            'error'
          )
        } else {
          if (this.singleFile) {
            this.file = event.target.files[0]
          } else this.files.push(event.target.files[0])
        }
      }
    },
    handleKeyDown(event) {
      console.log('Keydown event')
    }
  }
}
</script>
<style lang="scss" scoped>
.send-btn-icons {
  max-width: 100px;
}

.textfield--wrapper {
  .write__msg {
  }
}
.emoji-chat-picker {
  .v-icon {
    font-size: 30px !important;
  }
}
.action--wrapper {
  max-width: 140px;
  padding: 5px;
}

.hidden-input {
  display: none;
}

.write__msg {
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 2px 5px;
  min-height: 45px;
  max-height: 65px;
}

.atwho-panel {
  .mention-img {
    width: 20px;
    margin-right: 5px;
    border-radius: 50%;
  }
}
</style>
<style>
ul.atwho-ul {
  padding: 0px !important;
}
</style>
