<template>
  <CustomDialog
    v-model="dialog"
    @button-1="close"
    title="File Preview"
    :has-footer="false"
    :max-width="500"
  >
    <template v-slot:content>
      <v-row dense no-gutters class="pa-2 mb-5" v-if="media">
        <v-col md="3">
          <v-avatar size="100" tile>
            <Media :media="media" size="xl"></Media>
          </v-avatar>
          <v-btn text small block disabled>
            {{ media.size | bytesToSize }}
          </v-btn>
        </v-col>
        <v-col grow>
          <p class="headline file-title">File preview not yet supported</p>
          <p class="subtitle file-subtitle">
            {{ media.file_name }}
          </p>
          <v-btn
            dark
            class="text-none"
            target="_blank"
            color="primary"
            :href="media.download_url"
          >
            <v-icon left>mdi-cloud-download</v-icon> Download file or Go to link
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
export default {
  name: "OtherViewer",
  components: {
    CustomDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    media: { type: Object },
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
};
</script>
 <style>
</style>


