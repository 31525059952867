<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :elevation="hover ? 5 : 3" v-bind="$attrs">
      <v-card-title
        class="d-flex align-item-center justify-space-between primary--text subtitle-2"
      >
        <h4
          :title="file.title ? file.title : file.filename"
          class="font-weight-bold"
        >
          {{ file.title || file.filename | trunc_mid(7, 10) }}
        </h4>
        <FileMenu
          :file="file"
          :has-download="can_download"
          :is-file-image-or-video="isFileImageOrVideo"
          :has-modify="hasModify"
          :has-approved="isFileImageOrVideo && hasApproved"
          :has-reject="isFileImageOrVideo && hasReject"
          :has-edit="hasEdit"
          :has-delete="hasDelete"
          @view="$emit('view')"
          @delete="$emit('delete')"
          @edit="$emit('edit')"
          @modification="$emit('modification')"
          @approved="$emit('approved')"
          @reject="$emit('reject')"
        ></FileMenu>
      </v-card-title>
      <v-card-text
        class="d-flex align-item-center justify-center cursor-pointer"
      >
        <Attachment
          @attachment-clicked="$emit('view')"
          height="200"
          animated
          prefer-thumb
          :item="file"
          class="mx-auto"
        ></Attachment>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import FileMenu from './FileMenu.vue'
export default {
  components: { FileMenu },
  name: 'FileCard',
  inheritAttrs: false,
  props: {
    file: { type: Object, required: true },
    hasModify: { type: Boolean, default: false },
    hasApproved: { type: Boolean, default: false },
    hasReject: { type: Boolean, default: false },
    hasEdit: { type: Boolean, default: false },
    hasDelete: { type: Boolean, default: false },
    isFileImageOrVideo: { type: Boolean, default: false }
  },
  computed: {
    permissionsAll() {
      return this.$_permissions.get('hq_files')
    },
    permissionsOwn() {
      return this.$_permissions.get('hq_files_own')
    },
    can_download() {
      return this.file && this.file.category !== 'links'
    },
    status() {
      let current_status = this.file.status
      if (current_status === 'approved')
        return {
          icon: 'mdi-file-certificate',
          text: 'Approved',
          color: 'success'
        }
      if (current_status === 'reject')
        return { icon: 'mdi-file-alert', text: 'Rejected', color: 'error' }
      if (current_status === 'modification')
        return {
          icon: 'mdi-file-cad',
          text: 'For modification',
          color: 'accent'
        }
      return { icon: 'mdi-file', text: 'Pending', color: 'primary' }
    }
  }
}
</script>

<style></style>
